<template>
  <ul class="assignment-documents">
    <!---- Normal Assignment Attachments ---->
    <li v-for="(file, index) in documents"
        :key="file.name + index"
        :class="{'assignment-documents__item--icon' : mode === 'with-icon'}"
        class="assignment-documents__item">
      <span v-if="mode === 'with-icon'"
            class="icon assignment-documents__icon">icon</span>
      <!---- Image Attachment ---->
      <button v-if="file.contentType.split('/')[0] === 'image'"
              :title="file.name"
              target="_blank"
              class="assignment-documents__link"
              @click="openImagePreviewModal(file)">{{ file.name }}</button>
      <div v-else>
        <button v-if="userIsInterpreter"
                class="assignment-documents__link"
                @click="downloadFile(file)">{{ file.name }}</button>
        <!---- Attachment Different Than Image ---->
        <button v-else
                :title="file.name"
                class="assignment-documents__link"
                @click="openLink(file)">{{ file.name }}</button>
      </div>
      <button v-if="hasDelete"
              type="button"
              class="assignment-documents__remove-btn"
              @click="$emit('delete-attachment', file)"></button>
      <span v-if="file.filePassword"
            class="assignment-password__icon"
            @mouseleave="closeTooltip"
            @mouseover="openTooltipHandler($event)"
            @click="copyToClipboard(file.filePassword)"></span>
    </li>
    <!---- OCR Attachments ---->
    <div v-if="ocrDocuments.length"
         class="ocr-separator"></div>
    <li v-for="(file, index) in ocrDocuments"
        :key="file.name + index"
        :class="{'assignment-documents__item--icon' : mode === 'with-icon'}"
        class="assignment-documents__item">
      <span v-if="mode === 'with-icon'"
            class="icon assignment-documents__icon">icon</span>
      <button v-if="file.contentType.split('/')[0] === 'image'"
              :title="file.name"
              target="_blank"
              class="assignment-documents__link"
              @click="openImagePreviewModal(file)">{{ file.name }}</button>
      <div v-else>
        <button v-if="userIsInterpreter"
                class="assignment-documents__link"
                @click="downloadFile(file)">{{ file.name }}</button>
        <button v-else
                :title="file.name"
                class="assignment-documents__link"
                @click="openLink(file)">{{ file.name }}</button>
      </div>
      <button v-if="hasDelete"
              type="button"
              class="assignment-documents__remove-btn"
              @click="$emit('delete-attachment', file)"></button>
    </li>
  </ul>
</template>

<script>
  import constants from '@/modules/constants';
  import {mapGetters} from 'vuex';
  import helpers from '@/helpers';

  export default {
    props: {
      documents: {
        type: Array,
        default: () => []
      },
      ocrDocuments: {
        type: Array,
        default: () => []
      },
      mode: {
        type: String,
        default: ''
      },
      hasDelete: {
        type: Boolean,
        default: false
      },
      fromSubtask: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        apiPath: constants.API_PATH,
        tooltipBasePosition: null
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userIsInterpreter']),
      ...mapGetters('OneProjectStore', ['project']),
      disableDownload() {
        if (this.userIsInterpreter && (this.project.status === 'accepted' || this.project.status === 'in_progress' || this.project.status === 'review_requested' || this.project.status === 'changes_required')) {
          return true;
        } else if (!this.userIsInterpreter) {
          return true;
        } else if (this.fromSubtask) {
          for (const task of this.project.tasks) {
            if (task.subtasks.length) {
              for (const subtask of task.subtasks) {
                if (subtask.status === 'accepted' || subtask.status === 'in_progress' || subtask.status === 'review_requested' || subtask.status === 'changes_required') return true;
              }
            }
          }
        } else return false;
      },
      tooltipText() { return this.$gettext('Copy password'); },
      tooltipTextCopied() { return this.$gettext('Password copied'); }
    },
    methods: {
      closeModal() {
        this.$emit('closemodal');
      },
      openImagePreviewModal(file) {
        this.closeModal();
        this.$store.commit('ModalStore/setModal', {
          component: 'image-preview-modal',
          data: {
            file: file,
            disableDownload: this.disableDownload,
            modalCallback: this.openLink
          }
        });
      },
      downloadFile(file) {
        this.closeModal();
        this.$store.commit('ModalStore/setModal', {
          component: 'confirm-download-modal',
          data: {
            title: this.$gettext('Confirm downloading file'),
            text: this.$gettext('Translator accepts that he/she will have access to the files before he/she accepts the order. If the translator rejects, he/she agrees to delete all the files he/she previously downloaded that are associated with this order.'),
            context: this,
            modalCallback: this.openLink,
            callbackParams: file
          }
        });
      },
      openLink(file) {
        const a = document.createElement('a');
        a.href = this.imageLinkWithAuth(file.link);
        a.target = '_blank';
        a.click();
      },
      imageLinkWithAuth(link) {
        return this.apiPath + link + `?authorization_token=${this.$fetcher.userToken}&random=${Math.random()}`;
      },
      // tooltip password
      openTooltipHandler(event) {
        this.tooltipBasePosition = event;
        this.openTooltip(this.tooltipText, this.tooltipBasePosition);
      },
      itemPosition(event) {
        const {top, left} = helpers.getElementPosition(event.target);
        return {top: (top) + 'px', left: (left) + 'px'};
      },
      openTooltip(text, event) {
        this.$store.commit('TooltipRootStore/setTooltip', {
          data: {
            itemText: text,
            relativePosition: 'top-center'
          },
          absolutePosition: this.itemPosition(event)
        });
      },
      closeTooltip() { this.$store.dispatch('TooltipRootStore/closeTooltip'); },
      copyToClipboard(value) {
        navigator.clipboard.writeText(value).then(() => {
          this.openTooltip(this.tooltipTextCopied, this.tooltipBasePosition);
        });
      }
    }
  };
</script>

<style scoped>
.icon {
  position: absolute;
  top: 0;
  left: -5px;
  display: inline-block;
  vertical-align: middle;
  width: 17px;
  height: 20px;
  background-position: center left;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 0;
}

.ocr-separator {
  width: 100%;
  height: 1px;
  margin: 10px 5px;
  background-color: #0003;
}

.assignment-documents {
  list-style: none;
  font-size: 0.86rem;
}

.assignment-documents__item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 18px;
}

.assignment-documents__item:not(:first-child) {
  margin-top: 5px;
}

.assignment-documents__item--icon {
  padding-left: 18px;
}

.assignment-documents__link {
  display: block;
  max-width: 100%;
  color: #ff5b24;
  color: var(--color-primary);
  font-weight: bold;
  font-size: 14px;
  letter-spacing: inherit;
  text-decoration: underline;
  word-spacing: inherit;
  overflow-wrap: break-word;
}

.assignment-documents__link:hover {
  color: var(--color-secondary);
  text-decoration: none;
}

.assignment-documents__icon {
  background-image: var(--image-clipboard-icon);
}

.assignment-password__icon {
  display: block;
  width: 16px;
  height: 16px;
  margin-left: 6px;
  background-image: url(~@assets/imgs/salita/projects/password-lock-icon.svg);
  background-position: center center;
  background-repeat: no-repeat;
}

.assignment-documents__remove-btn {
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  display: block;
  width: 15px;
  background-image: url(~@assets/imgs/undefined_imgs/trash_ico.svg);
  background-position: 50% 50%;
  background-size: 12px auto;
  background-repeat: no-repeat;
}
</style>
